import React, { useState, Fragment } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import Video from "@components/Video";
import SEO from "@components/SEO";
import Layout from "@components/Layout";

import { measurementVideos } from "@utils/static-data";

import styles from "@cssmodules/measurements.module.scss";

export default function Measurement() {
    const [activeTabs, setActiveTabs] = useState({ index: 0 });
    const images = useStaticQuery(graphql`
        query UkuranQuery {
            contohUkuran: file(relativePath: { eq: "measurement-guide.png" }) {
                childImageSharp {
                    fluid {
                        aspectRatio
                        base64
                        sizes
                        src
                        srcSet
                    }
                }
            }
            tabelUkuran: file(relativePath: { eq: "measurement-table.png" }) {
                childImageSharp {
                    fluid {
                        aspectRatio
                        base64
                        sizes
                        src
                        srcSet
                    }
                }
            }
        }
    `);

    const renderMeasurementList = () => {
        return measurementVideos.map((data, idx) => (
            <div
                key={idx}
                className={`d-flex mb-1 ${styles.list} ${
                    idx === activeTabs.index ? styles.active : ``
                }`}
                onClick={() => setActiveTabs({ index: idx })}
            >
                <span>{idx + 1}.&nbsp;</span>
                <span dangerouslySetInnerHTML={{ __html: data.title }}></span>
            </div>
        ));
    };

    const renderMeasurementContent = () => {
        let title = measurementVideos[activeTabs.index].title;
        let video_link = measurementVideos[activeTabs.index].link;
        return (
            <Fragment>
                <h4
                    style={{
                        background: "#eee",
                        padding: "0.5rem",
                        borderRadius: "1rem",
                        textAlign: "center",
                    }}
                    dangerouslySetInnerHTML={{
                        __html: `${activeTabs.index + 1}. ${title}`,
                    }}
                ></h4>
                <div className={styles.video}>
                    <Video title={title} link={video_link} quality="sd" />
                </div>
            </Fragment>
        );
    };

    return (
        <Layout>
            <main id="main-content" className={styles.main}>
                <SEO title="Standar Ukuran" />
                <Container>
                    <h2 className={`${styles.title} d-none d-md-block`}>
                        Jangan khawatir soal ukuran, berikut video tutorial cara{" "}
                        <br />
                        mengukur dan juga standar ukuran di Gudang Seragam
                    </h2>
                    <h2 className={`${styles.title} d-block d-md-none`}>
                        Jangan khawatir soal ukuran, berikut
                        <br /> video tutorial cara mengukur
                        <br /> dan juga standar ukuran
                        <br /> di Gudang Seragam
                    </h2>
                    <Row className="flex-column-reverse flex-md-row">
                        <Col xs={12} md={6} className="mb-3">
                            <Card
                                className="border-0"
                                style={{
                                    backgroundColor: "#eee",
                                    borderRadius: "1.5rem",
                                    height: "20rem",
                                }}
                            >
                                <Card.Body>
                                    <div className={styles.listContainer}>
                                        {renderMeasurementList()}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} md={6} className="mb-3">
                            {renderMeasurementContent()}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4} className="mb-3">
                            <Card
                                className="border-0"
                                style={{
                                    backgroundColor: "#eee",
                                    borderRadius: "1.5rem",
                                    height: "25rem",
                                }}
                            >
                                <Card.Body>
                                    <Img
                                        fluid={
                                            images.contohUkuran.childImageSharp
                                                .fluid
                                        }
                                        className="w-100 h-100"
                                        imgStyle={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "unset",
                                            objectPosition: "unset",
                                        }}
                                        alt="guide ukuran"
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} md={8} className="mb-3">
                            <Card
                                className="border-0"
                                style={{
                                    backgroundColor: "#eee",
                                    borderRadius: "1.5rem",
                                    height: "25rem",
                                }}
                            >
                                <Card.Body>
                                    <Img
                                        fluid={
                                            images.tabelUkuran.childImageSharp
                                                .fluid
                                        }
                                        className="w-100 h-100"
                                        imgStyle={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "unset",
                                            objectPosition: "unset",
                                        }}
                                        alt="tabel ukuran"
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </main>
        </Layout>
    );
}
